import React, { useState, useEffect } from "react";

import { navigate } from 'gatsby';
import queryString from "query-string";

import { BrandData } from "models/brand";
import { getBrandDataFromPath, getCategoryMetadataFromPath } from "utils/jsonUtils";
import { adEngagePath } from "utils/constants";
import { fetchBrandData } from "utils/ad_engage";

import AdEngageLayout from "components/layout";
import BrandOverview from "components/brand_overview";

export interface TopBrandProps {
  category?: string;
  brand?: string;
}

const BrandOverviewPublicAdEngage: React.FC<TopBrandProps> = ({ category, brand }) => {

  const { brandId, brandName } = getBrandDataFromPath(brand);
  const [brandData, setBrandData] = useState<BrandData>();
  const [fromTopBrands, setFromTopBrands] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);

    fetchBrandData(brandId)
      .then(brandData => {
        setBrandData(brandData);
        setLoading(false);
      })
      .catch((err) => {
        // TODO: REPORT ERROR
        // TODO: retry fetch?

        // redirect back to parent category if no data for brand
        navigate(adEngagePath + "/" + category);
        setLoading(false);
      });

    if (typeof window != "undefined") {
      setFromTopBrands(queryString.parse(location?.search)?.fromTopBrands === "true")
    }
  }, [brand]);

  return (
    <AdEngageLayout pageTitle={brandData?.brand_data?.brand_name ? brandData?.brand_data?.brand_name + " - Brand Overview" : "Brand Overview"}>
      <BrandOverview
        brand={{ brandId, brandName }}
        category={getCategoryMetadataFromPath(category)}
        brandData={brandData}
        fromTopBrands={fromTopBrands}
        loading={loading}
      >
      </BrandOverview>

    </AdEngageLayout>
  );
};

export default BrandOverviewPublicAdEngage;
